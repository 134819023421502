@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.formChooseItem {
  margin-top: 50px;
  .formChooseItemTitle {
    color: #000;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
  }
  .options {
    margin-top: 40px;
    padding: 0 36px;
    .option {
      margin-bottom: 20px;
      width: 100%;
      height: 52px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 52px;
      text-align: center;
      color: #333;
      background: #F5F6F8;
      &.active {
        color: #fff;
        background: linear-gradient( 135deg, #8284FF 0%, #383BD8 100%);
      }
    }
  }
}
