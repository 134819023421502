@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.iosSkin {
  height: 100%;
  line-height: normal;
  background-color: #f5f6f9;

  .main {
    padding: 20px 16px 10px;
    background-repeat: no-repeat;
    background-size: 100% 432px;
    background-image: url("~@imgs/ios-skin/head-bg.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      @include flex-cc;

      .logo {
        margin-right: 10px;
        width: 99px;
        height: 56px;
      }
    }

    .applyResultBox {
      margin-top: 15px;
      background: linear-gradient(314deg, #FFFAF9 0%, #FFEEE6 100%);
      border-radius: 7px;
      border: 2px solid #DD2231;
      display: flex;
      align-items: center;
      padding: 9px;
      .applyResultBoxIcon {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        margin-right: 8px;
      }
      .applyResultBoxRight {
        .applyResultBoxText1 {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          .applyPrductionName {
            color: #F80109;
          }
        }
        .applyResultBoxText2 {
          margin-top: 4px;
          font-size: 14px;
          color: #967569;
          .highlight {
            color: #F80109;
          }
        }
      }
    }

    .containers {
      margin-top: 13px;
      border-radius: 14px;
      background-color: #fff;
      padding: 36px;
      &.passBox {
        height: 460px;
      }
      &.failBox {
        height: 550px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .noPass {
          margin-top: 30px;
          width: 164px;
          height: 92px;
        }
        .failBoxText1 {
          margin: 14px 0 13px;
          font-size: 21px;
          font-weight: bold;
          color: #FF041D;
        }
        .failBoxText2 {
          font-size: 15px;
          color: #555555;
        }
        .failBoxText3 {
          margin-top: 4px;
          font-size: 13px;
          color: #9C9C9C;
        }
      }
      .line {
        width: 1px;
        height: 35px;
        margin-left: 7px;
      }
      .containerItem {
        display: flex;
        align-items: center;
        .applyResultBoxIcon {
          width: 17px;
          height: 17px;
          margin-right: 24px;
        }
        .containerItemSuccessText {
          color: #FF2E43;
          font-size: 18px;
          font-weight: bold;
        }
        .containerItemFailText {
          color: #939393;
          font-size: 18px;
        }
      }
    }

    .btnBox {
      width: 219px;
      padding: 0 16px;
      margin-top: 150px;
      position: fixed;
      bottom: 200px;
      left: 50%;
      transform: translateX(-50%);
      .btn {
        border: none;
        padding: 0;
        height: 48px;
        background: $linear-gradient;
        line-height: 50px;
        font-size: 16px;
      }
    }
  }
}
